export const educationData = [
    {
        id: 1,
        institution: 'Masai School, Banglore',
        course: 'Full Stack Web Development',
        startYear: 'Sep, 2021',
        endYear: 'Mar, 2022',
        img:"https://user-images.githubusercontent.com/90475607/189502368-71901e5e-d353-4018-a7b9-65c5fc2b54f5.png"
    },
    {
        id: 2,
        institution: 'IGNOU University, Delhi',
        course: 'Bachelor of Computer Applicaition',
        startYear: 'July, 2016',
        endYear: 'Dec, 2019',
        img:"https://user-images.githubusercontent.com/90475607/189502369-78e37b4f-87ca-4705-ae02-76651f6625d2.png"
    },
    {
        id: 3,
        institution: 'Indian Institue of Computer Science, Delhi',
        course: 'Master Diploma in Computer Engineering',
        startYear: 'Jul, 2016',
        endYear: 'Jul, 2019',
        img:"https://user-images.githubusercontent.com/90475607/189688962-3fbb7b57-0423-4126-a1cd-32092fdb7ef3.png"
    },
]