import turboFingers from '../assets/gif/TurboFingers.gif'
import resumeBuilder from '../assets/gif/resume_builder.gif'
import boult from '../assets/gif/boult.gif'
import dotandkey from '../assets/gif/dotandkey.gif'
import weather from '../assets/gif/weather.gif'
import colorGenerator from '../assets/gif/colorGenerator.gif'


export const projectsData = [
    {
        id: 1,
        projectName: 'Turbo Fingers',
        projectDesc: 'It is a platform where you can test your typing speed by attempting the typing test. As well as you can keep track of your pasts tests taken by you.',
        tags: ['React', 'Redux', 'SASS','Express','Node JS', 'MongoDB', 'Firebase'],
        code: 'https://github.com/MohdTazammul/turbo-fingers',
        demo: 'https://turbo-fingers.web.app/',
        image: turboFingers
    },
    {
        id: 2,
        projectName: 'Resume Builder',
        projectDesc: 'This project aims to build resumes with multiple templates on the basis of filled data by the user',
        tags: ['React', 'Material UI', 'NodeJS','Express', 'MongoDB', 'CSS', 'JavaScript', 'Cloudinary'],
        code: 'https://github.com/MohdTazammul/Resume-Automation',
        demo: 'https://resume.masaischool.com/',
        image: resumeBuilder
    },
    {
        id: 3,
        projectName: 'Boult Audio Clone',
        projectDesc: 'Boult Audio is an e-commerce website where a user can buy TWS, Headphones, Smart watches, etc.',
        tags: ['React', 'Redux', 'NodeJS','ExpressJS','MongoDB', 'Material UI', 'CSS'],
        code: 'https://github.com/MohdTazammul/boult-audio-frontend',
        demo: 'https://boult-audio.netlify.app/',
        image: boult
    },
    {
        id: 4,
        projectName: 'Dot and Key Clone',
        projectDesc: 'Dot and Key Skincare is a new age skincare brand with products such as Serum, Moisturizer and skincare products.',
        tags: ['HTML', 'CSS', 'JavaScript', 'JSON', 'LocalStorage'],
        code: 'https://github.com/MohdTazammul/Dot-and-Key-Skincare-clone',
        demo: 'https://dotandkeyskincare.netlify.app/',
        image: dotandkey
    },
    {
        id: 5,
        projectName: 'Color Generator',
        projectDesc: 'Color Generator will list you other shades of your given color on a RGB range. You can search the shades of a color by using valid HexaDecimal color code or by simply any color name. can customize the frequency of color shades (1-100)',
        tags: ['React', 'Styled components', 'HTML','CSS','JavaScript'],
        code: 'https://github.com/MohdTazammul/color-generator',
        demo: 'https://color-generator-hexadecimal.netlify.app/',
        image: colorGenerator
    },
    {
        id: 6,
        projectName: 'Weather Forecast',
        projectDesc: 'A web application to show weather forecast reports of different cities with other details (i.e. humidity, wind speed, sunset and sunrise time with google map).',
        tags: ['HTML', 'CSS', 'JavaScript', 'Google Maps API', 'OpenWeather map API'],
        code: 'https://github.com/MohdTazammul/Weather-Forecast',
        demo: 'https://weather-forecast-webapplication.netlify.app/',
        image: weather
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/