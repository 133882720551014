import React, { useContext } from 'react';

import './Achievement.css';
import { ThemeContext } from '../../contexts/ThemeContext';
import { achievementData } from '../../data/achievementData'
// import AchievementCard from './AchievementCard';
// import { HiArrowRight } from "react-icons/hi";
// import { Icon } from '@iconify/react';
function Achievement() {

    const { theme } = useContext(ThemeContext);
    return (
        <>
            {achievementData.achievements.length > 0 && (
                <div id="achievement" style={{ backgroundColor: theme.secondary }}>
                    <h1 style={{ color: theme.primary }}>Achievement</h1>
                    <div className="achievement">
                        <div className="achievement-body">
                            <h4 style={{ color: theme.tertiary }}>I'm in the <span>top 100</span> among <span>245k developers </span> across the globe and have solved all <span>132 problems</span> with lesser lines of code on <span>CSSBattle</span>. It is the first ever code-golfing platform for CSS lovers. The objective of the game is to write HTML/CSS to replicate the given target image in the least code possible.</h4>
                            <a target={"_blank"} href={"https://cssbattle.dev/player/mohdtazammul"}><button>Go to my profile <span>&#10140;</span></button></a>
                        </div>
                        <div className="achievement-cards">
                            <div>
                                <img width={"100%"} src={"https://user-images.githubusercontent.com/90475607/195286752-1fbb5a2a-eaba-4dd6-b577-42356a74d7ad.png"} />
                            </div>
                        </div>
                        <div className='achievement-profile'>
                            <a target={"_blank"} href={"https://cssbattle.dev/player/mohdtazammul"}><button>Go to my profile <span>&#10140;</span></button></a>
                        </div>

                    </div>
                </div>
            )}
        </>
    )
}

export default Achievement
