export const experienceData = [
    {
        id: 1,
        company: 'GetSetYo Technology Labs',
        jobtitle: 'Software Engineer',
        startYear: 'Nov, 2022',
        endYear: 'Present',
        place:"Bangalore, Karnataka",
        img:'https://user-images.githubusercontent.com/90475607/221380508-337ef7d4-ae51-4369-8c7b-ba9df628c8e0.png'
    },
    {
        id: 2,
        company: 'Masai School',
        jobtitle: 'Instructional Associate',
        startYear: 'Mar, 2022',
        endYear: 'Oct, 2022',
        place:"Bangalore, Karnataka (Remote)",
        img:'https://user-images.githubusercontent.com/90475607/189502368-71901e5e-d353-4018-a7b9-65c5fc2b54f5.png'
    },
    {
        id: 3,
        company: 'Sachdeva Institute of Management & Technology',
        place:'Patel Nagar, New Delhi (Remote)',
        jobtitle: 'BCA Faculty',
        startYear: 'Feb, 2021',
        endYear: 'Aug, 2021',
        img:'https://user-images.githubusercontent.com/90475607/221380288-c4351846-e7a2-43d6-b1bd-641747910d04.png'
    }
]